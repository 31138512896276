import { cloneDeep } from 'lodash';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, ParamMap, NavigationEnd, RouterLink } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { filter, take, map, shareReplay } from 'rxjs/operators';
import moment from 'moment';

import { ChangeSelectionComponent } from 'src/app/layout/header/change-selection/change-selection.component';
import { AuthService } from 'src/app/_shared/services';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
// eslint-disable-next-line no-restricted-imports
import packageJson from '../../../../package.json';
import { IAccount } from 'src/app/_shared/classes/account';
import { IUser } from 'src/app/_shared/classes/user';
import * as fromStore from 'src/app/_store/_reducers';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { canSelectFromPartners } from 'src/app/_shared/interface/partner.interfacre';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { IMeasurementPoint } from 'src/app/_shared/classes/measurementpoint.interface';
import { UserActions } from 'src/app/_store/_user/actions';
import { ReleaseNewsComponent } from 'src/app/layout/header/release-news/release-news.component';
import { WelcomeDialogComponent } from 'src/app/layout/header/welcome-dialog/welcome-dialog.component';
import { matDialogFocus } from 'src/app/_shared/helpers/dialog-focus';
import { FirmwareUpdateFeature, RmsAnalysisFeature, NotificationGroupFeature } from 'src/app/_shared/classes/features';
import { NewsActions } from 'src/app/_store/_news/actions';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { EventTrackingDirective } from '../../_shared/tracking/tracking.directive';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatTooltip } from '@angular/material/tooltip';
import { HintBoxComponent } from '../../_shared/components/hint-box/hint-box.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatDivider } from '@angular/material/divider';
import { LoaderComponent } from '../../_shared/components/loader/loader.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    MatToolbar,
    MatIconButton,
    MatIconModule,
    EventTrackingDirective,
    CdkOverlayOrigin,
    MatTooltip,
    RouterLink,
    HintBoxComponent,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatDivider,
    LoaderComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class HeaderComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @Input() mobileMenuOpen = false;
  @Output() mobileMenuOpenChange = new EventEmitter();
  @Output() actionTrigger = new EventEmitter();

  userAccount: IAccount;
  contactInfo: IAccount;
  mpId: number;
  showMp: boolean = true;
  canSelectMp: Observable<boolean>;
  version = packageJson.version;
  isSysAdmin: boolean;
  isAdministrator: boolean;
  isEngineer: boolean;
  isPartner: boolean;
  isSysAdmin$: Observable<boolean>;
  headerName$: Observable<string>;
  viewerMode$: Observable<boolean>;
  firmwareUpdateFeature$: Observable<FirmwareUpdateFeature>;
  notificationCenterFeature$: Observable<NotificationGroupFeature>;
  rmsVariationAnalysisFeature$: Observable<RmsAnalysisFeature>;
  loading = false;
  mp: IMeasurementPoint;
  firstName: string;
  user: IUser;
  hintFirmwareIsOpened: boolean;
  hintNotificationIsOpened: boolean;
  hintFirmwareAndNotificationIsOpened: boolean;
  private accountId: number;
  private userPrefs: UserPreferences;

  @HostListener('psl-show-change-selection')
  onShowChangeSelection(): void {
    this.changeSelection();
  }

  constructor(
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    private store: Store<fromStore.State>
  ) {
    super();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showMp =
          (!window.location.href.includes('/notification') &&
            !window.location.href.includes('/admin') &&
            !window.location.href.includes('/manage-member') &&
            !window.location.href.includes('/rms-variation-analysis')) ||
          window.location.href.includes('/customer');
      }
    });
  }

  ngOnInit(): void {
    this.canSelectMp = this.store.select(fromStore.getPartners).pipe(map(canSelectFromPartners), shareReplay(1));
    this.isSysAdmin$ = this.store.select(fromStore.getIsSysAdmin);
    this.headerName$ = this.store.select((state) => state.user.headerName);
    this.viewerMode$ = this.store.select((state) => state.user.viewerMode);
    this.firmwareUpdateFeature$ = this.store.select(fromStore.getFeatureFirmwareUpdate);
    this.notificationCenterFeature$ = this.store.select(fromStore.getFeatureNotificationGroup);
    this.rmsVariationAnalysisFeature$ = this.store.select(fromStore.getFeatureRmsAnalysis);

    this.route.queryParamMap.pipe(untilComponentDestroyed(this)).subscribe(async (params: ParamMap) => {
      forkJoin([
        this.store.pipe(select(fromStore.getAccount)).pipe(take(1)),
        this.store.pipe(select(fromStore.getUser)).pipe(take(1)),
        this.store.pipe(select(fromStore.getUserPreferences)).pipe(take(1)),
        this.store.pipe(select(fromStore.getMp)).pipe(take(1)),
        this.store.pipe(select(fromStore.getIsPartner)).pipe(take(1)),
        this.store.pipe(select(fromStore.getNews)).pipe(take(1)),
        this.isSysAdmin$.pipe(take(1)),
        this.store.select(fromStore.getIsAdmin).pipe(take(1)),
      ]).subscribe(([userAccount, user, userPreferences, mp, isPartner, news, isSysAdmin, isAdministrator]) => {
        this.isSysAdmin = isSysAdmin;
        this.isAdministrator = isAdministrator;
        this.userPrefs = cloneDeep(userPreferences);
        if (
          params &&
          params.get('mpId') &&
          /^\d+$/.test(params.get('mpId')) &&
          params.get('account') &&
          /^\d+$/.test(params.get('account'))
        ) {
          this.mpId = parseInt(params.get('mpId'), 10);
          this.accountId = parseInt(params.get('account'), 10);
        } else if (Object.keys(userPreferences).length !== 0 && userPreferences.mpId) {
          this.mpId = userPreferences.mpId;
          this.accountId = userPreferences.account;
          this.router.navigate([], {
            queryParams: {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              ...params['params'],
              account: this.accountId.toString(),
              mpId: this.mpId.toString(),
            },
          });
        } else {
          this.canSelectMp
            .pipe(
              take(1),
              filter((x) => x)
            )
            .subscribe(() => this.changeSelection());
        }
        this.contactInfo = userAccount;
        this.userAccount = userAccount;
        this.isPartner = isPartner === 1;
        this.firstName = user.firstName;

        this.user = user;
        if (this.user) {
          this.isEngineer = this.user.role.name === 'Engineer';
        }

        if (mp) {
          this.mp = mp;

          if (
            this.userPrefs.onBoarding?.isOnBoarding &&
            moment.duration(moment().diff(moment(this.userPrefs.onBoarding?.timestamp))).asMinutes() > 10
          ) {
            this.store.dispatch(
              UserActions.updateUserPreferences({
                payload: {
                  property: {
                    onBoarding: {
                      isOnBoarding: false,
                      timestamp: null,
                    },
                  },
                },
              })
            );
          }
        } else if (this.accountId && this.mpId && this.user) {
          this.canSelectMp
            .pipe(
              take(1),
              filter((x) => x)
            )
            .subscribe(() => {
              if (this.dialog.openDialogs.length === 0) {
                return this.changeSelection();
              }
            });
        }

        if (userPreferences.welcomeMessage) {
          matDialogFocus(this.openWelcomeMessage());
        }
      });
    });

    this.store
      .pipe(select(fromStore.getHint))
      .pipe(untilComponentDestroyed(this))
      .subscribe((hint) => {
        this.hintFirmwareIsOpened =
          hint?.createMp || hint?.associate
            ? false
            : hint?.hasOwnProperty('firmwareUpdate')
            ? hint.firmwareUpdate
            : true;

        this.hintNotificationIsOpened =
          hint?.createMp || hint?.associate
            ? false
            : hint?.hasOwnProperty('notificationCenter')
            ? hint.notificationCenter
            : true;

        this.hintFirmwareAndNotificationIsOpened =
          hint?.createMp || hint?.associate
            ? false
            : hint?.hasOwnProperty('firmwareAndNotification')
            ? hint.firmwareAndNotification
            : true;
      });

    this.store
      .pipe(select(fromStore.getNewId))
      .pipe(untilComponentDestroyed(this))
      .subscribe((newsId) => {
        if (
          !this.userPrefs.welcomeMessage &&
          !this.isSysAdmin &&
          this.userPrefs.latestNewsId !== -1 &&
          this.userPrefs.latestNewsId < newsId
        ) {
          this.openReleaseNews();
          this.store.dispatch(
            UserActions.updateUserPreferences({
              payload: { property: { latestNewsId: newsId } },
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  navigateManageMember(url: string, accountId: number): void {
    this.menuClick();
    this.setPreviousUrl();
    this.router.navigate([url, accountId]);
  }

  menuClick(): void {
    this.actionTrigger.emit(false);
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpenChange.emit(!this.mobileMenuOpen);
  }

  changeSelection(): void {
    this.dialog.open(ChangeSelectionComponent, {
      autoFocus: false,
      width: '1000px',
      maxWidth: '98vw',
      maxHeight: '98vh',
    });
  }

  openPrivacyPolicy(): void {
    window.open('https://powerside.com/privacy-policy/', '_blank');
  }

  logout(): void {
    this.authService.logout();
    this.notificationsService.notify(this.translateService.instant('login.logged-out'));
  }

  openReleaseNews(): void {
    this.isSysAdmin$.pipe(take(1)).subscribe((isSysAdmin) => {
      this.store.dispatch(NewsActions.openNews());
      this.dialog.open(ReleaseNewsComponent, {
        width: '950px',
        maxWidth: '98vw',
        maxHeight: '98vh',
        disableClose: false,
        autoFocus: false,
        restoreFocus: false,
        panelClass: 'front-panel',
      });
    });
  }

  openWelcomeMessage(): MatDialogRef<WelcomeDialogComponent> {
    return this.dialog.open(WelcomeDialogComponent, {
      width: '950px',
      maxWidth: '98vw',
      maxHeight: '98vh',
      disableClose: false,
      data: {
        firstName: this.firstName,
      },
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'front-panel',
    });
  }

  private setPreviousUrl(): void {
    if (!this.router.url.includes('manage-member')) {
      const previousUrl = this.router.url.substring(0, this.router.url.indexOf('?'));
      this.store.dispatch(UserActions.setPreviousUrl({ payload: { url: previousUrl } }));
    }
  }
}
